<template>
    <div class="warning-config-base">
        <sn-bread :itemName="itemName" :breadlist="breadlist"></sn-bread>
        <router-view class="warning-config-view"></router-view>
    </div>
</template>
<script>
export default {
    name: "warning_config",
    data() {
        return {
            itemName: "预警配置",
            breadlist: [
                {
                    path: "",
                    name: "业务管理",
                },
            ],
        };
    },
};
</script>
<style lang="scss" scoped>
.warning-config-base {
    width: 100%;
    height: calc(100% - 45px);
    padding: 0 20px;
    .warning-config-view {
        width: 100%;
        height: calc(100% - 45px);
    }
}
</style>
